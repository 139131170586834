<template>
  <MainLayout>
    <template v-slot:navbar-title>
      Информация о прошивке
    </template>
    <template v-slot:navbar-btn-wrap>
      <DeletePopup
        name="прошивку"
        :title="
          sourceObject
            ? 'ПО ' + sourceObject.chip + ' ' + sourceObject.version + '?'
            : ''
        "
        :delete-function="deleteFirmware"
        :isDescription="false"
      />

      <router-link
        v-if="urlQuery && urlQuery.id"
        :to="`/firmwareEdit/` + urlQuery.id"
        class="btn btn-edit btn_color_grey"
        >редактировать</router-link
      >
    </template>
    <SlideLayout :tabs="slideTabs">
      <template v-slot:info>
        <div class="keep__title">
          <p class="keep__text">Общая информация</p>
        </div>
        <BasicTableWithoutHeader
          v-if="sourceObject"
          :table-data="getDataRequisites"
        >
          <template #item.5.value="{item}">
            <p
              :style="
                item.value == 'Активен'
                  ? 'color: #95C23D !important'
                  : 'color: #EB5C6D !important'
              "
            >
              {{ item.value }}
            </p>
          </template>
        </BasicTableWithoutHeader>
      </template>
    </SlideLayout>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import SlideLayout from "@/components/MoreInfo/SlideLayout";
import * as query from "@/helpers/query";
import BasicTableWithoutHeader from "@/components/tables/BasicTableWithoutHeader";

import DeletePopup from "@/components/popup/DeletePopup";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";

import moment from "moment";

export default {
  name: "FirmwaresInfosPages",
  components: {
    SlideLayout,
    MainLayout,
    BasicTableWithoutHeader,
    DeletePopup
  },
  data() {
    return {
      type: "firmwares",
      queries: query.organizations,
      infoTabs: [
        {
          text: "Общая информация",
          value: "recv"
        }
      ],
      headers: [
        {
          value: "text",
          text: "text"
        },
        {
          value: "value",
          text: "value"
        }
      ]
    };
  },
  methods: {
    deleteFirmware() {
      this.$store
        .dispatch("deleteFirmware", { id: this.urlQuery.id })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.remove(successMessageDictionary.firmwares)
          );
        });
    }
  },
  computed: {
    slideTabs() {
      return [
        {
          text: "Общая информация",
          value: "info",
          icon: "i",
          role: [10, 20, 21, 30, 31, 40, 50, 60, 70]
        }
      ];
    },
    sourceObject() {
      return this.$store.getters.getFirmwareInfo;
    },
    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id
      };
    },
    getDataRequisites() {
      return [
        {
          text: "ID",
          value: this.sourceObject.id
        },
        {
          text: "Чип",
          value: this.sourceObject.chip
        },
        {
          text: "Версия чипа",
          value: this.sourceObject.board_version
            ? this.sourceObject.board_version
            : "-"
        },
        {
          text: "Версия прошивки",
          value: this.sourceObject.version
        },
        {
          text: "Тестовая",
          value: this.sourceObject.is_test ? "Да" : "Нет"
        },
        {
          text: "Статус",
          value: this.sourceObject.is_active ? "Активен" : "Не активен"
        },
        {
          text: "Расположение файла",
          value: this.sourceObject.file
        },
        {
          text: "Дата создания",
          value: moment.unix(this.sourceObject.created_at).format("DD.MM.YYYY")
        },
        {
          text: "Комментарий",
          value: this.sourceObject.comment ? this.sourceObject.comment : "-"
        }
      ];
    }
  },

  created() {
    this.$store.commit("setSearchQuery", {
      id: this.urlQuery.id,
      query: { ...this.urlQuery.query, type: this.type }
    });
    this.isDateLoaded = true;
    this.$store
      .dispatch("fetchFirmwareInfo", {
        id: this.$route.params.id
      })
      .finally(() => {
        this.isDateLoaded = false;
      });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path === from.path) {
      this.$store.commit("setSearchQuery", {
        id: this.urlQuery.id,
        query: {
          ...to.query
        }
      });
      this.isDateLoaded = true;
      this.$store
        .dispatch("fetchFirmwareInfo", {
          id: this.$route.params.id
        })
        .finally(() => {
          this.isDateLoaded = false;
        });
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("clearFirmwareInfo").then(() => next());
  }
};
</script>

<style scoped></style>
